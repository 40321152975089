import styled from 'styled-components';
import Link from 'Elements/Link';

import { SocialWrapper as PromoPointSocials } from 'Modules/PromoPoint/Socials/Socials.styled';
import { SocialsWrapper as OpenDaysContactUsSocials } from 'Modules/OpenDays/Section/ContactUs/ContactUs.styled';

export const List = styled.ul`
    display: inline;
    padding: 0;
    margin: 0;
`;

export const Item = styled.li`
    display: inline;
    margin: 0 0.5rem;

    ${PromoPointSocials} &, ${OpenDaysContactUsSocials} & {
        margin: 0 1.125rem;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const StyledLink = styled(Link)`
    color: currentColor;
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.8;
    }
`;

export const Icon = styled.svg`
    width: 1.85rem;
    height: 1.85rem;

    ${PromoPointSocials} &, ${OpenDaysContactUsSocials} & {
        width: 2.5rem;
        height: 2.5rem;
    }
`;
