import React from 'react';
import NextImage from 'next/image';

import type { Props } from 'Modules/SiteNavigation/subcomponents/Promotion';

import { Container, Link } from './Promotion.styled';

const Promotion: React.FC<Props> = props => {
    const { image, text, link } = props;

    return (
        <Container>
            <NextImage alt="" {...image} fill style={{ objectFit: 'cover' }} />
            <Link {...link} label={undefined}>
                {text}
            </Link>
        </Container>
    );
};

export type { Props };
export default Promotion;
