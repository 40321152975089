import React, { useState, useRef, useEffect } from 'react';
import { useTransition } from '@react-spring/web';

import type { Props as SubnavigationProps } from './subcomponents/Subnavigation';
import Subnavigation from './subcomponents/Subnavigation';

import {
    Button,
    Wrapper,
    Navigation,
    NavList,
    NavItem,
    NavItemButton,
    LinkIcon,
    SubnavigationContainer
} from './Hamburger.styled';

interface Item {
    id: string;
    name: string;
    subnavigation: Omit<SubnavigationProps, 'closeFnc' | 'closeNavigationFnc'>;
}

export interface Props {
    items: Item[];
}

const Hamburger: React.FC<Props> = props => {
    const { items } = props;
    const [openNavigationID, setOpenNavigationID] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navListRef = useRef() as React.MutableRefObject<HTMLUListElement>;
    const navItemsRef = useRef<HTMLLIElement[]>([]);

    const activeNavigationItem = items.find(item => item.id === openNavigationID);

    const wrapperTransition = useTransition(isOpen, {
        from: {
            left: '-105%'
        },
        enter: {
            left: '0%'
        },
        leave: {
            left: '-105%'
        },
        config: {
            tension: 200,
            friction: 30
        }
    });

    const subnavigationTransition = useTransition(activeNavigationItem, {
        from: { left: '100%' },
        enter: { left: '0%' },
        leave: { left: '100%' },
        config: {
            tension: 140,
            friction: 20
        }
    });

    const handleNavigationClick: React.MouseEventHandler<HTMLElement> = event => {
        const target = event.target as HTMLElement;

        if (target.nodeName === 'A') {
            setIsOpen(false);
            setOpenNavigationID(null);
        }
    };

    const toggleNavigationID = (id: string) => {
        if (openNavigationID === id) {
            setOpenNavigationID(null);
        } else {
            setOpenNavigationID(id);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            // Close any open subnavigation on menu close
            setOpenNavigationID(null);
        }
    }, [isOpen]);

    return (
        <div>
            <Button
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                type="button"
                aria-label={`${isOpen ? 'Close' : 'Open'} the site menu`}
                $isOpen={isOpen}
            >
                <span />
                <span />
                <span />
            </Button>
            {wrapperTransition(
                (styles, visible) =>
                    visible && (
                        <Wrapper
                            style={styles}
                            $isSubnavigationOpen={!!activeNavigationItem}
                            onClick={handleNavigationClick}
                        >
                            {!activeNavigationItem && (
                                <Navigation>
                                    <NavList $isOpen={openNavigationID !== null} ref={navListRef}>
                                        {items.map((item, index) => (
                                            <NavItem
                                                key={item.id}
                                                ref={(element: HTMLLIElement) =>
                                                    (navItemsRef.current[index] = element)
                                                }
                                            >
                                                <NavItemButton
                                                    onClick={() => toggleNavigationID(item.id)}
                                                    type="button"
                                                >
                                                    {item.name}
                                                    <LinkIcon
                                                        $isOpen={openNavigationID === item.id}
                                                    />
                                                </NavItemButton>
                                            </NavItem>
                                        ))}
                                    </NavList>
                                </Navigation>
                            )}
                            {subnavigationTransition(
                                (styles, item) =>
                                    item && (
                                        <SubnavigationContainer style={styles}>
                                            <Subnavigation
                                                {...item.subnavigation}
                                                closeFnc={() => setOpenNavigationID(null)}
                                            />
                                        </SubnavigationContainer>
                                    )
                            )}
                        </Wrapper>
                    )
            )}
        </div>
    );
};

export default Hamburger;
