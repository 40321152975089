import styled, { css } from 'styled-components';

import { Wrapper as BaseWrapper, Icon as BaseIcon } from '../Icon.styled';

export const Wrapper = styled(BaseWrapper)<{ $layout?: string }>`
    ${({ $layout }) => css`
        border: 0.1rem solid currentColor;
        border-radius: 50%;

        ${$layout === 'fill' &&
        css`
            height: 100%;
            width: 100%;
        `}
    `}
`;

export const Icon = styled(BaseIcon)`
    max-width: calc(0.9em - 0.25rem);
    max-height: calc(0.9em - 0.25rem);
    stroke-width: 0.1rem;
`;
