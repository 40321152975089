import React from 'react';

import { Wrapper, Icon as IconStyled } from './Icon.styled';

export interface Props {
    icon: React.ElementType;
    onClick?: React.MouseEventHandler;
    className?: string;
    style?: React.CSSProperties;
}

const Icon: React.FC<Props> = props => {
    const { icon, className, onClick, style } = props;

    return (
        <Wrapper className={className} onClick={onClick} style={style}>
            <IconStyled as={icon} />
        </Wrapper>
    );
};

export default Icon;
