import React from 'react';
import CSS from 'csstype';

import { Wrapper, Background } from './Section.styled';

export type Padding = 'default' | 'none';
export type PaddingWithValue = Padding | number;

export interface Props {
    id: string;
    children: string | React.ReactNode;
    className?: string;
    backgroundStart?: boolean;
    background?: boolean;
    backgroundEnd?: boolean;
    backgroundColor?: string;
    backgroundTop?: string;
    backgroundStartTop?: string;
    shape?: 'box' | 'circle';
    gradient?: boolean;
    padding?: Padding;
    paddingTop?: PaddingWithValue;
    paddingBottom?: PaddingWithValue;
    align?: 'left' | 'center' | 'right';
    overflow?: CSS.Property.Overflow;
    colour?: CSS.Property.Color;
}

const Section: React.FC<Props> = props => {
    const {
        id,
        className,
        children,
        backgroundStart,
        backgroundStartTop,
        background,
        backgroundEnd,
        backgroundColor,
        backgroundTop,
        shape = 'circle',
        gradient,
        padding = 'default',
        paddingTop = 'default',
        paddingBottom = 'default',
        align = 'left',
        overflow,
        colour
    } = props;

    return (
        <Wrapper
            id={id}
            className={className}
            $padding={padding}
            $paddingTop={paddingTop}
            $paddingBottom={paddingBottom}
            $align={align}
            $overflow={overflow}
            $colour={colour}
        >
            {(backgroundStart || background || backgroundEnd) && (
                <Background
                    $backgroundStart={backgroundStart}
                    $background={background}
                    $backgroundEnd={backgroundEnd}
                    $backgroundColor={backgroundColor}
                    $backgroundTop={backgroundTop}
                    $backgroundStartTop={backgroundStartTop}
                    $shape={shape}
                    $gradient={gradient}
                />
            )}
            {children}
        </Wrapper>
    );
};

export default Section;
