import { createSelector } from 'reselect';
import { name, initialState } from './slice';
import type { SiteAlertState } from './types';

const selectSiteAlert = createSelector(
    (state: SiteAlertState) => state[name] || initialState,
    substate => substate
);

export default selectSiteAlert;
