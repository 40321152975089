import styled, { css } from 'styled-components';

import Button from '../Button';
import { ButtonIconVariants } from 'Theme/types/elements.type';

export const Container = styled(Button)<{ $variant: ButtonIconVariants }>`
    ${({ theme, $variant }) => css`
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 700;
        white-space: normal;
        transition: all 200ms ease;

        && {
            border: ${theme.elements.buttonIcon[$variant].border};
            color: ${theme.elements.buttonIcon[$variant].color};
            background: ${theme.elements.buttonIcon[$variant].background};

            &:hover {
                background: ${theme.elements.buttonIcon[$variant].hover?.background};
                color: ${theme.elements.buttonIcon[$variant].hover?.color};
                border: ${theme.elements.buttonIcon[$variant].hover?.border};
            }
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1.25rem;
        }
    `}
`;

export const Icon = styled.svg<{ $variant: ButtonIconVariants }>`
    ${({ theme, $variant }) => css`
        width: 1.5rem;
        min-width: 1.5rem;
        margin-left: 1rem;
        color: ${theme.elements.buttonIcon[$variant].color};
        transition: all 200ms ease;
        stroke-width: ${theme.commonStyles.brandPixel};

        ${Container}:hover & {
            color: ${theme.elements.buttonIcon[$variant].hover?.color};
        }

        @media ${theme.breakpoints.large.media} {
            width: 2.5rem;
            min-width: 2.5rem;
        }
    `}
`;
