import styled, { css } from 'styled-components';
import type { LinkVariants } from 'Theme/types/elements.type';

import Link from '../Link';
import { Chevron } from '@solent-university/solent-icons';

const iconTranslateValue = '0.25em';

interface ContainerProps {
    $variant?: LinkVariants;
    $outline?: boolean;
    $iconColor?: string;
    $iconBorder?: boolean;
}

export const Container = styled(Link)<ContainerProps>`
    ${({ theme, $variant = 'default', $outline, $iconColor }) => css`
        --icon-color: ${$outline
            ? $iconColor || theme.elements.link[$variant].color
            : $iconColor || theme.elements.link[$variant].icon.color};

        position: relative;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.5;

        @media ${theme.breakpoints.large.media} {
            font-size: 1.5rem;
        }
    `}
`;

export const IconContainer = styled.span<{
    $outline?: boolean;
    $iconBorder?: boolean;
    $isHovered?: boolean;
}>`
    ${({ $outline, $isHovered, $iconBorder = true }) => css`
        --border-width: 0.0625rem;

        display: block;
        position: relative;
        width: calc(1.35em - (var(--border-width) * 2));
        height: calc(1.35em - (var(--border-width) * 2));
        transition: all 200ms ease;
        margin-left: ${$iconBorder ? '0.35em' : '0'};
        margin-right: 0.35em;
        background: ${$outline ? 'transparent' : 'currentColor'};
        border-radius: 50%;

        ${$iconBorder &&
        css`
            border: var(--border-width) solid currentColor;
        `}

        ${Container}:hover & {
            transform: translateX(${iconTranslateValue});
        }

        ${$isHovered &&
        css`
            transform: translateX(${iconTranslateValue});
        `}
    `}
`;

export const Icon = styled(Chevron)`
    position: absolute;
    width: 0.75em;
    height: 0.75em;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% + 0.025em), -50%);
    color: var(--icon-color);
`;
