import styled, { css } from 'styled-components';

import BaseLink from 'Elements/Link/Arrow';

export const Container = styled.div`
    position: relative;
    height: 3.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Link = styled(BaseLink)`
    ${({ theme }) => css`
        position: relative;
        color: ${theme.modules.siteNavigation.secondaryNavigation.color};
        line-height: 1.2;
        font-weight: 700;
    `}
`;
