import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { useSpring } from '@react-spring/web';

import { SiteAlertVariants } from 'Theme/types/components.type';
import useSiteAlert from 'Redux/siteAlert';

import { ArrowLinkProps } from 'Elements/Link/Arrow';

import {
    Container,
    Button,
    ButtonInner,
    StyledIcon,
    ButtonText,
    ArrowWrapper,
    Arrow,
    AnimatedInner,
    Inner,
    StyledArrowLink
} from './SiteAlert.styled';

export interface Props {
    id: string;
    icon: React.ElementType;
    heading: string;
    description: React.ReactNode;
    link?: ArrowLinkProps;
    variant: SiteAlertVariants;
}

const SiteAlert: React.FC<Props> = props => {
    const { id, icon, heading, description, link, variant } = props;

    const { store, toggleIsExpanded } = useSiteAlert();
    const isExpanded = store.isExpanded;

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(isExpanded ? true : false);
    const innerRef = useRef() as MutableRefObject<HTMLDivElement | null>;

    const innerSpring = useSpring({
        height: isExpanded && innerRef.current ? `${innerRef.current.scrollHeight}px` : '0px',
        onStart: () => {
            if (innerRef.current && isExpanded) {
                innerRef.current.style.visibility = 'visible';
            }
        },
        onRest: () => {
            setIsFirstLoad(false);
            if (innerRef.current) {
                isExpanded
                    ? (innerRef.current.style.visibility = 'visible')
                    : (innerRef.current.style.visibility = 'hidden');
            }
        },
        config: {
            duration: isFirstLoad ? 0 : 250
        }
    });

    useEffect(() => {
        function handleResize() {
            if (innerRef.current) {
                innerRef.current.style.height = 'auto';
            }
        }
        if (isExpanded) {
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [isExpanded]);

    return (
        <Container id={id} $variant={variant}>
            <Button
                $variant={variant}
                onClick={() => toggleIsExpanded()}
                aria-label={isExpanded ? 'Close alert' : 'Open alert'}
                aria-expanded={isExpanded}
                aria-controls="site-alert-info"
            >
                <ButtonInner>
                    <StyledIcon as={icon} />
                    <ButtonText>{heading}</ButtonText>
                    <ArrowWrapper>
                        <Arrow $isExpanded={isExpanded} />
                    </ArrowWrapper>
                </ButtonInner>
            </Button>
            <AnimatedInner
                id="site-alert-info"
                ref={innerRef}
                style={innerSpring}
                aria-hidden={isExpanded ? false : true}
                role="region"
            >
                <Inner>
                    {description}
                    {link && <StyledArrowLink {...link} $variant={variant} />}
                </Inner>
            </AnimatedInner>
        </Container>
    );
};

export default SiteAlert;
