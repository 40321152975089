import React from 'react';
import type { Props as IconProps } from '../Icon';

import { Wrapper, Icon } from './Border.styled';

interface Props extends IconProps {
    layout?: 'fill';
}

const Border: React.FC<Props> = props => {
    const { icon, layout, ...rest } = props;

    return (
        <Wrapper {...rest} $layout={layout}>
            <Icon as={icon} />
        </Wrapper>
    );
};

export default Border;
