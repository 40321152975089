import React from 'react';

import Image, { StaticImageData } from 'next/image';
import ArrowLink from 'Elements/Link/Arrow';

import { Wrapper, ImageWrapper, Content, Text } from './Promotion.styled';

export interface Props {
    image: {
        src: StaticImageData | string;
        alt?: string;
    };
    text: string;
    link: {
        label: string;
        href: string;
        ariaLabel?: string;
    };
}

const Promotion: React.FC<Props> = props => {
    const { image, text, link } = props;

    return (
        <Wrapper>
            <ImageWrapper>
                <Image alt="" {...image} fill style={{ objectFit: 'cover' }} />
            </ImageWrapper>
            <Content>
                <Text>{text}</Text>
                <ArrowLink {...link} />
            </Content>
        </Wrapper>
    );
};

export default Promotion;
