import styled, { css } from 'styled-components';
import BaseIcon from 'Elements/Icon/Icon';

export const SkipButton = styled.a`
    ${({ theme }) => css`
        opacity: 0;
        top: 0;
        left: 1%;
        position: fixed;
        z-index: -1;
        background-color: ${theme.colors.primary.hex};
        color: ${theme.colors.secondary.hex};
        padding: 0.5rem 2.5rem 0.5rem 1.5rem;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.3125rem;
        border-radius: 1.5rem;
        box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
        transition: 0.5s all cubic-bezier(0, 0.59, 0.25, 1.18);

        &:focus {
            visibility: visible;
            z-index: 10000;
            opacity: 1;
            transform: translateY(1rem);
        }
    `}
`;

export const Icon = styled(BaseIcon)`
    width: 1.75rem;
    height: 1.75rem;
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);

    @media ${({ theme }) => theme.breakpoints.large.media} {
        width: 2.25rem;
        height: 2.25rem;
    }
`;
