import React from 'react';

import type { LinkProps } from 'Elements/Link';

import Promotion, { Props as PromotionProps } from '../Promotion';

import {
    Container,
    PrimaryNavigation,
    PrimaryList,
    Item,
    Link,
    Icon,
    SecondaryNavigationContainer,
    SecondaryNavigation,
    SecondaryHeading,
    SecondaryList,
    PromotionWrapper,
    Tag
} from './Subnavigation.styled';

export interface Props {
    label: string;
    primaryNavigation: {
        links: (LinkProps & { id: string; icon: React.ElementType; iconColor: string })[];
    };
    secondaryNavigation: {
        heading: string;
        links: (LinkProps & { id: string; tag?: 'trending' | 'updated' })[];
    };
    promotion?: PromotionProps;
}

const SubNavigation: React.FC<Props> = props => {
    const { label, primaryNavigation, secondaryNavigation, promotion } = props;

    return (
        <Container>
            <PrimaryNavigation aria-label={`${label} main navigation`}>
                <PrimaryList>
                    {primaryNavigation.links.map(({ label, id, icon, iconColor, ...rest }) => (
                        <Item key={id}>
                            <Link {...rest}>
                                <Icon as={icon} $iconColor={iconColor} />
                                {label}
                            </Link>
                        </Item>
                    ))}
                </PrimaryList>
            </PrimaryNavigation>
            <SecondaryNavigationContainer>
                <SecondaryNavigation aria-label={`${label} secondary navigation`}>
                    <SecondaryHeading>{secondaryNavigation.heading}</SecondaryHeading>
                    <SecondaryList>
                        {secondaryNavigation.links.map(({ id, tag, label, ...rest }) => (
                            <Item key={id}>
                                <Link {...rest}>
                                    {label}
                                    {tag && <Tag $type={tag}>{tag}</Tag>}
                                </Link>
                            </Item>
                        ))}
                    </SecondaryList>
                </SecondaryNavigation>
                {promotion && (
                    <PromotionWrapper $fullHeight={secondaryNavigation.links.length === 0}>
                        <Promotion {...promotion} />
                    </PromotionWrapper>
                )}
            </SecondaryNavigationContainer>
        </Container>
    );
};

export default SubNavigation;
