import { css, Interpolation, ThemeProps, DefaultTheme } from 'styled-components';

const scrollbar = (variant = 'default'): Interpolation<ThemeProps<DefaultTheme>> => css`
    ${({ theme }) => {
        const { track, thumb } = theme.commonStyles.scrollbar[variant];

        return css`
            ::-webkit-scrollbar {
                width: 0.5rem;
                height: 0.5rem;
            }

            ::-webkit-scrollbar-track {
                background: ${track.background};
                border: 0.0625rem solid ${track.border};
                border-radius: 0.375rem;
            }

            ::-webkit-scrollbar-thumb {
                background: ${thumb.background};
                border-radius: 0.375rem;

                &:hover {
                    background: ${thumb.hover.background};
                }
            }
        `;
    }}
`;

export default scrollbar;
