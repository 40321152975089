import React, { useState, useEffect } from 'react';
import { useSpring } from '@react-spring/web';
import { useRouter } from 'next/router';
import { Chevron } from '@solent-university/solent-icons';

import { Wrapper, StyledIcon, Text } from './ScrollToTop.styled';

const TOTAL_SCREENS_TO_SHOW = 3;

const ScrollToTop: React.FC = () => {
    const [windowHeight, setWindowHeight] = useState(2500);
    const [isVisible, setIsVisible] = useState(false);
    const [hover, setHover] = useState(false);

    const router = useRouter();

    const checkVisibility = () => {
        window.pageYOffset >= windowHeight * TOTAL_SCREENS_TO_SHOW
            ? setIsVisible(true)
            : setIsVisible(false);
    };

    const scrollToTop = () => {
        setHover(false);
        router.push('#__next');
    };

    useEffect(() => {
        function handleResize() {
            setWindowHeight(window.innerHeight);
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        checkVisibility();

        window.addEventListener('scroll', checkVisibility);
        return () => window.removeEventListener('scroll', checkVisibility);
    }, [windowHeight]);

    const springConfig = {
        mass: 1,
        tension: 200,
        friction: 20,
        duration: 150
    };

    // Fade in the button while scrolling
    const fadeInElementSpring = useSpring({
        opacity: isVisible ? '1' : '0',
        config: springConfig
    });

    // Show text only after button expands
    const fadeInTextSpring = useSpring({
        opacity: hover ? '1' : '0',
        delay: 300,
        config: springConfig
    });

    return isVisible ? (
        <Wrapper
            id="backToTop"
            aria-label="Scroll back to top"
            onClick={scrollToTop}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onFocus={() => setHover(true)}
            onBlur={() => setHover(false)}
            style={fadeInElementSpring}
        >
            {hover && <Text style={fadeInTextSpring}>Back to top</Text>}
            <StyledIcon icon={Chevron} />
        </Wrapper>
    ) : null;
};

export default ScrollToTop;
