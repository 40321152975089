import React from 'react';

import { StaticImageData } from 'next/image';

export interface Props extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> {
    src: StaticImageData | string;
    alt?: string;
}

const Image: React.FC<Props> = props => {
    const { src, alt, ...rest } = props;

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={typeof src === 'string' ? src : src.src} alt={alt ? alt : ''} {...rest} />;
};

export default Image;
