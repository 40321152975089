import styled, { css } from 'styled-components';

import { Cross } from '@solent-university/solent-icons';
import BaseSearch from '../Search';
import { Input, Button, SearchIcon, ButtonIcon } from '../Search.styled';

const customBreakpointSmall = 1250;
const customBreakpointLarge = 1300;

export const Container = styled.div<{ $isOpen: boolean }>`
    ${({ theme, $isOpen }) => css`
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-right: ${$isOpen ? '1.5rem' : '0.5rem'};

        @media ${`only screen and (min-width: ${customBreakpointLarge / 16}rem)`} {
            margin-right: 1rem;
        }

        ${Button} {
            height: 2rem;
            width: auto;
            font-size: 1rem;
            font-weight: 700;
            padding: 0 0.75rem;

            ${$isOpen &&
            css`
                padding: 0 0.75rem;
            `}

            @media ${theme.breakpoints.medium.media} {
                padding: 0;

                ${$isOpen &&
                css`
                    padding: 0 0.75rem;
                `}
            }

            @media ${theme.breakpoints.xlarge.media} {
                padding: 0 0.75rem;
            }
        }

        ${SearchIcon} {
            position: relative;
            right: 0;
            top: 0;
            display: block;
            width: 1.5rem;
            height: 1.5rem;

            ${$isOpen &&
            css`
                position: absolute;
                width: 1rem;
                height: 1rem;
                top: 50%;
                transform: translateY(-50%);
                right: 0.25rem;
            `}

            @media ${theme.breakpoints.xlarge.media} {
                position: absolute;
                width: 1rem;
                height: 1rem;
                top: 50%;
                transform: translateY(-50%);
                right: 0.5rem;
            }
        }

        ${ButtonIcon} {
            width: 3rem;
            height: 3rem;
            top: 1.3rem;
            left: 1.3rem;
        }
    `}
`;

export const CloseButton = styled.button`
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    margin-right: 1rem;
    border: none;
    padding: 0;
    line-height: 1;
    background: none;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

export const CloseIcon = styled(Cross)`
    display: block;
    width: 100%;
    height: 100%;
`;

export const Search = styled(BaseSearch)`
    ${({ theme }) => css`
        width: 11rem;

        @media ${theme.breakpoints.xlarge.media} {
            width: 6.25rem;
        }

        @media ${`only screen and (min-width: ${customBreakpointSmall / 16}rem)`} {
            width: 9rem;
        }

        @media ${`only screen and (min-width: ${customBreakpointLarge / 16}rem)`} {
            width: 11rem;
        }

        ${Input} {
            padding: 0.5rem 1.5rem 0.5rem 0.5rem;
            border: 0.06rem solid black;
            height: 2rem;
            background: ${theme.colors.primaryContrast.hex};
            font-size: 1rem;

            &:focus {
                box-shadow: none;
            }
        }
    `}
`;

export const ExpandButton = styled(Button)`
    ${({ theme }) => css`
        border-radius: 0.5rem;
        width: 1.5rem;
        height: 3.5rem;
        cursor: pointer;
        background-color: transparent;
        color: ${theme.colors.text.hex};
        position: relative;
        padding: 0;
        margin: 0;

        @media ${theme.breakpoints.xlarge.media} {
            background-color: ${theme.colors.interactive.hex};
            color: ${theme.colors.interactiveContrast.hex};
        }
    `}
`;
