import { useState, useEffect, useCallback } from 'react';

const getViewportOrientation = (width: number, height: number): string => {
    return width < height ? 'portrait' : 'landscape';
};

const useViewport = (): { width: number; height: number; orientation: string } => {
    const [viewport, setViewport] = useState(
        typeof window !== 'undefined'
            ? {
                  width: window.innerWidth,
                  height: window.innerHeight,
                  orientation: getViewportOrientation(window.innerWidth, window.innerHeight)
              }
            : {
                  width: 0,
                  height: 0,
                  orientation: 'landscape'
              }
    );

    const updateViewport = useCallback(
        () =>
            setViewport({
                width: window.innerWidth,
                height: window.innerHeight,
                orientation: getViewportOrientation(window.innerWidth, window.innerHeight)
            }),
        [viewport.width, viewport.height]
    );

    useEffect(() => {
        updateViewport();
        window.addEventListener('resize', updateViewport);

        return () => window.removeEventListener('resize', updateViewport);
    }, []);

    return viewport;
};

export default useViewport;
