import styled, { css } from 'styled-components';

import Link from 'Elements/Link';

export const UtilityContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

interface LogoWrapperProps {
    $isSticky: boolean;
    $isSearchOpen: boolean;
}

export const LogoWrapper = styled.div<LogoWrapperProps>`
    ${({ theme, $isSticky, $isSearchOpen }) => css`
        position: absolute;
        width: 6.5rem;
        height: 6.5rem;
        top: 50%;
        left: 4rem;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;

        ${$isSticky &&
        css`
            top: -0.9rem;
            transform: translateX(-50%);
        `}

        @media ${theme.breakpoints.smallLarge.media} {
            top: 50%;

            ${$isSticky &&
            css`
                top: -0.9rem;
                transform: translateX(-50%);
            `}
        }

        ${$isSearchOpen &&
        css`
            display: none;

            @media ${theme.breakpoints.medium.media} {
                display: block;
            }
        `}

        @media ${theme.breakpoints.xlarge.media} {
            width: 10rem;
            height: 10rem;
            position: relative;
            transform: none;
            top: 0.25rem;
            left: 1rem;

            ${$isSticky &&
            css`
                top: 1rem;
                width: 6.5rem;
                height: 6.5rem;
            `}
        }
    `}
`;

export const LogoLink = styled(Link)`
    display: inline-block;
    width: 100%;
    height: 100%;
`;

export const EndContainer = styled.div`
    ${({ theme }) => css`
        display: inline-flex;
        height: 100%;
        background-color: ${theme.modules.topBar.background};
    `}
`;

export const SearchWrapper = styled.div<{ $isVisible: boolean }>`
    ${({ theme, $isVisible }) => css`
        height: 100%;
        align-items: center;
        display: ${$isVisible ? 'flex' : 'none'};

        @media ${theme.breakpoints.xlarge.media} {
            margin-left: initial;
        }
    `}
`;

export const ButtonWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        height: 100%;
        transform: translateX(-50%);
        display: none;

        @media ${theme.breakpoints.smallLarge.media} {
            display: flex;
        }

        @media ${theme.breakpoints.xlarge.media} {
            transform: translateX(0%);
        }
    `}
`;

export const HamburgerContainer = styled.div`
    ${({ theme }) => css`
        z-index: 1;

        @media ${theme.breakpoints.medium.media} {
            padding: 0 0.5rem 0 0.75rem;
        }
    `}
`;

export const XLWrapper = styled.div<{ $isSticky: boolean }>`
    ${({ theme, $isSticky }) => css`
        background-color: ${theme.modules.topBar.background};

        ${!$isSticky &&
        css`
            box-shadow: ${theme.modules.siteNavigation.boxShadow};
        `}
    `}
`;

export const XLargeContainer = styled.div`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.xlarge.media} {
            max-width: 100rem;
            width: 100%;
            margin: 0 auto;
            background-color: ${theme.modules.topBar.background};
            display: block;
        }
    `}
`;

export const SmallContainer = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.modules.topBar.background};
        box-shadow: ${theme.modules.siteNavigation.boxShadow};

        @media ${theme.breakpoints.xlarge.media} {
            display: none;
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        max-width: 100rem;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        @media ${theme.breakpoints.xlarge.media} {
            justify-content: space-between;
        }
    `}
`;

export const Container = styled.div<{ $isSticky: boolean }>`
    ${({ theme, $isSticky }) => css`
        display: flex;
        height: 5rem;
        width: 100%;
        padding: 0 1rem;
        background-color: ${theme.modules.topBar.background};
        justify-content: ${!$isSticky ? 'flex-end' : 'space-between'};

        position: relative;
        z-index: 999;

        ${$isSticky &&
        css`
            box-shadow: ${theme.modules.siteNavigation.boxShadow};
            transition: height 300ms ease, background 250ms ease;
            position: fixed;
            top: 0;
            height: 3.5rem;
            left: 0;
            flex-direction: row-reverse;
            padding-left: 50%;

            @media ${theme.breakpoints.xlarge.media} {
                flex-direction: row;
                padding-left: 0;
            }
        `}

        @media ${theme.breakpoints.xlarge.media} {
            height: 6.5rem;

            ${$isSticky &&
            css`
                height: 3.25rem;
            `}
        }
    `}
`;
