import React from 'react';

import WaveDesktopPNG from 'Public/graphics/waves/footer/wave.png';
import WaveMobilePNG from 'Public/graphics/waves/footer/wave-mobile.png';

import type { Props as SocialIconsProps } from 'Components/SocialIcons/SocialIcons';
import type { Props as LinkProps } from 'Elements/Link/Link';
import SocialIcons from 'Components/SocialIcons/SocialIcons';
import Link from 'Elements/Link/Link';
import Grid, { Col } from 'Components/Grid';

import {
    Wrapper,
    Content,
    SocialIconsWrapper,
    AwardsContainer,
    Award,
    Navigation,
    Label,
    List,
    GovernanceWrapper,
    GovernanceList,
    GovernanceNavigation,
    ListItem,
    ListInlineItem,
    AwardSocialContainer,
    GovernanceLabel,
    WaveMobile,
    WaveDesktop
} from './Footer.styled';

export interface Link extends LinkProps {
    id?: string;
}
export interface Props {
    socialLinks: SocialIconsProps;
    navigation: {
        label: string;
        items: Link[];
    }[];
    awards: {
        id: string;
        image: {
            src: string;
            alt: string;
        };
        link: {
            href: string;
            ariaLabel?: string;
        };
    }[];
    governanceNavigation: Link[];
}

const Footer: React.FC<Props> = ({ socialLinks, awards, navigation, governanceNavigation }) => {
    return (
        <Wrapper>
            <WaveMobile src={WaveMobilePNG} alt="" loading="lazy" />
            <Grid>
                <Col l={[2, 12]}>
                    <Content>
                        <Navigation aria-label="Footer">
                            {navigation.map(section => (
                                <div key={section.label}>
                                    <Label>{section.label}</Label>
                                    <List>
                                        {section.items.map(({ id, ...rest }) => (
                                            <ListItem key={id}>
                                                <Link {...rest} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            ))}
                        </Navigation>
                        <AwardSocialContainer>
                            <WaveDesktop src={WaveDesktopPNG} alt="" loading="lazy" />
                            <AwardsContainer>
                                {awards.map(({ id, link, image }) => (
                                    <a key={id} href={link.href} aria-label={link.ariaLabel}>
                                        <Award {...image} loading="lazy" height="72" width="192" />
                                    </a>
                                ))}
                            </AwardsContainer>
                            <SocialIconsWrapper>
                                <SocialIcons {...socialLinks} />
                            </SocialIconsWrapper>
                        </AwardSocialContainer>
                    </Content>
                </Col>
            </Grid>

            <GovernanceWrapper>
                <Grid>
                    <Col>
                        <GovernanceNavigation aria-label="Governance">
                            <GovernanceLabel>© Solent University</GovernanceLabel>
                            <GovernanceList>
                                {governanceNavigation.map(({ id, ...rest }) => (
                                    <ListInlineItem key={id}>
                                        <Link {...rest} />
                                    </ListInlineItem>
                                ))}
                            </GovernanceList>
                        </GovernanceNavigation>
                    </Col>
                </Grid>
            </GovernanceWrapper>
        </Wrapper>
    );
};

export default Footer;
