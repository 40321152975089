import { useSelector, useDispatch } from 'react-redux';

import selectSiteAlert from './selectors';
import { actions } from './slice';
import { SiteAlertState } from './types';

const useSiteAlert = (): {
    store: SiteAlertState;
    toggleIsExpanded: () => void;
} => {
    const dispatch = useDispatch();
    const store = useSelector(selectSiteAlert);

    const toggleIsExpanded = () => {
        dispatch(actions.toggleIsExpanded());
    };

    return { store, toggleIsExpanded };
};

export default useSiteAlert;
