import styled, { css } from 'styled-components';

import Image from 'Elements/Image';
import { Icon } from 'Components/SocialIcons/SocialIcons.styled';

export const WaveMobile = styled(Image)`
    ${({ theme }) => css`
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;

        @media ${theme.breakpoints.medium.media} {
            display: none;
        }
    `}
`;

export const WaveDesktop = styled(Image)`
    ${({ theme }) => css`
        display: none;
        position: absolute;
        left: -11rem;
        bottom: 0rem;
        height: 50%;
        z-index: -1;
        transform: rotate(270deg);

        @media ${theme.breakpoints.medium.media} {
            display: block;
            height: 500%;
            transform: unset;
            left: unset;
            top: -29rem;
            right: -23%;
        }

        @media ${theme.breakpoints.large.media} {
            height: 200%;
            right: -16rem;
            top: -9rem;
        }
    `}
`;

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        padding-top: 3rem;
        text-align: center;
        overflow: hidden;
        z-index: 0;
        background-color: ${theme.modules.footer.background};

        @media ${theme.breakpoints.medium.media} {
            text-align: left;
        }

        @media ${theme.breakpoints.large.media} {
            overflow: hidden;
        }
    `}
`;

export const SocialIconsWrapper = styled.div`
    ${({ theme }) => css`
        color: white;
        margin-bottom: 2rem;

        ${Icon} {
            width: 2rem;
            height: 2rem;
            outline: none;
        }

        @media ${theme.breakpoints.medium.media} {
            text-align: right;
            margin-bottom: 0;
        }
    `}
`;
export const Award = styled(Image)`
    height: 3rem;
    width: auto;
    max-width: 12rem;
    transition: all 200ms ease-in;

    &:hover {
        opacity: 0.7;
        transform: scale(0.95);
        transform-origin: center center;
    }

    @media ${({ theme }) => theme.breakpoints.large.media} {
        height: 4.5rem;
    }
`;

export const AwardsContainer = styled.div`
    margin: 2rem 0;
    display: flex;
    justify-content: end;

    a {
        margin-right: 1rem;
    }

    *:last-of-type {
        margin-right: 0;
    }

    @media ${({ theme }) => theme.breakpoints.large.media} {
        margin: 3rem 0;
        justify-content: flex-start;
    }
`;

export const AwardSocialContainer = styled.div`
    ${({ theme }) => css`
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media ${theme.breakpoints.medium.media} {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        @media ${theme.breakpoints.large.media} {
            display: unset;
            align-items: unset;
            flex-direction: unset;
            justify-content: unset;
        }
    `}
`;

export const Navigation = styled.nav`
    ${({ theme }) => css`
        display: block;
        position: relative;
        z-index: 3;
        grid-template-columns: repeat(3, calc(100% / 3));

        @media ${theme.breakpoints.medium.media} {
            display: grid;
        }

        @media ${theme.breakpoints.large.media} {
            margin-top: 1rem;
            column-gap: 1rem;
        }
    `}
`;

export const Label = styled.div`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        font-weight: 400;
        font-size: 1.5rem;
        color: ${theme.modules.footer.linkHeading.color};
    `}
`;

export const List = styled.ul`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        padding-left: 0;
        padding-bottom: 1.5rem;
        text-decoration: underline;

        @media ${({ theme }) => theme.breakpoints.large.media} {
            font-size: 1.2rem;
        }
    `}
`;

export const ListItem = styled.li`
    ${({ theme }) => css`
        list-style-type: none;
        font-size: 1.125rem;
        padding: 0.25rem 0;

        a {
            color: white;
            font-weight: 300;
            text-decoration: none;
            line-height: 1;

            @media ${theme.breakpoints.large.media} {
                font-size: 1rem;
            }

            @media ${theme.breakpoints.xlarge.media} {
                font-size: 1.2rem;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    `}
`;

export const GovernanceLabel = styled.div`
    ${({ theme }) => css`
        color: white;
        padding: 0.25rem 0;
        font-size: 0.9rem;

        @media ${theme.breakpoints.medium.media} {
            margin-top: 1rem;
        }
    `}
`;

export const GovernanceWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        background: ${theme.modules.footer.governanceNav.background};
        z-index: 3;
    `}
`;

export const GovernanceNavigation = styled.nav`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        padding-bottom: 1rem;
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;

        @media ${theme.breakpoints.medium.media} {
            margin-top: 2rem;
            justify-content: space-between;
            flex-direction: row;
        }

        @media ${theme.breakpoints.large.media} {
            justify-content: space-between;
            flex-direction: row;
            transform: none;
            padding-bottom: 0;
            margin-top: 1rem;
        }
    `}
`;

export const GovernanceList = styled.ul`
    ${({ theme }) => css`
        padding-left: 0;
        display: grid;

        @media ${theme.breakpoints.medium.media} {
            display: flex;
        }
    `}
`;

export const ListInlineItem = styled.li`
    ${({ theme }) => css`
        list-style-type: none;
        margin: 0;
        padding: 0.25rem 0;
        font-size: 0.9rem;
        color: white;

        @media ${theme.breakpoints.medium.media} {
            &:not(last-child) {
                margin-right: 1.5rem;
            }
        }

        &:first-child {
            order: 1;

            @media ${theme.breakpoints.large.media} {
                order: initial;
            }
        }

        a {
            color: white;
            font-weight: 300;
            font-size: 0.9rem;
            line-height: 1;
            &:hover {
                text-decoration: none;
            }
        }
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media ${theme.breakpoints.medium.media} {
            justify-content: flex-start;
            margin: 0 5rem;
        }

        @media ${theme.breakpoints.large.media} {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            /* To position the content behind the marker on desktop */
            position: relative;
            z-index: 3;
            margin: 0;
        }
    `}
`;
