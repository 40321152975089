import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import { Chevron } from '@solent-university/solent-icons';

const buttonSize = 1.25;

const calculateHypotenuse = (aSide: number, bSide: number): number =>
    Math.sqrt(Math.pow(aSide, 2) + Math.pow(bSide, 2));

export const Button = styled.button<{ $isOpen: boolean }>`
    --width: ${buttonSize + 0.25}rem;

    background: none;
    border: none;
    cursor: pointer;
    line-height: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: var(--width);
    height: ${buttonSize}rem;
    transition: all 200ms ease;

    ${({ theme, $isOpen }) => css`
        span {
            width: var(--width);
            height: 0.125rem;
            background: ${theme.colors.tertiary.hex};
            transition: all 250ms ease;
        }

        ${$isOpen &&
        css`
            margin: 0 0.25rem;
            width: ${buttonSize}rem;

            span {
                width: ${Math.floor(calculateHypotenuse(buttonSize, buttonSize) * 10) / 10}rem;

                &:nth-child(1) {
                    transform: rotate(44deg);
                    transform-origin: center left;
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-44deg);
                    transform-origin: center left;
                }
            }
        `}
    `}
`;

export const Wrapper = styled(animated.div)<{ $isSubnavigationOpen: boolean }>`
    ${({ theme, $isSubnavigationOpen }) => css`
        background: ${theme.colors.tertiary.hex};
        color: ${theme.colors.secondary.hex};
        border-radius: 0 0.75rem 0.75rem 0;
        display: flex;
        z-index: 100;
        overflow: hidden;
        position: absolute;
        top: 100%;
        width: 100%;
        max-width: calc(100vw - 1.25rem);
        left: 0;
        height: ${$isSubnavigationOpen ? '70vh' : 'auto'};

        @media ${theme.breakpoints.medium.media} {
            height: ${$isSubnavigationOpen ? '75vh' : 'auto'};

            @media (min-height: 56.25rem) {
                height: ${$isSubnavigationOpen ? '55vh' : 'auto'};
            }
        }
    `}
`;

export const Navigation = styled.nav`
    ${({ theme }) => css`
        display: flex;
        padding: 2.25rem 1.5rem 3rem 1.125rem;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: relative;

        @media ${theme.breakpoints.medium.media} {
            width: 50%;
        }
    `}
`;

export const NavList = styled.ul<{ $isOpen: boolean }>`
    list-style: none;
    padding: 0;
    max-height: 100%;
    overflow: auto;
    margin: 0;
`;

export const NavItem = styled.li`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1.125rem;
        direction: ltr;
        text-align: left;
        font-weight: 700;

        &:not(:last-child) {
            padding-bottom: 1.5rem;
        }

        @media ${theme.breakpoints.medium.media} {
            &:not(:last-child) {
                padding-bottom: 2.25rem;
            }
        }
    `}
`;

export const NavItemButton = styled.button`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
    user-select: none;
    position: relative;
    background: transparent;
    padding: 0;
    border: none;
    color: inherit;
    font-size: inherit;
`;

export const LinkIcon = styled(Chevron)<{ $isOpen: boolean }>`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: all 250ms ease;
    width: 1.25rem;
    height: 1.25rem;
`;

export const SubnavigationContainer = styled(animated.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;
