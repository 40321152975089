import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import { Chevron } from '@solent-university/solent-icons';

export const Navigation = styled.nav`
    display: block;
    height: 100%;
`;

export const NavList = styled.ul`
    margin: 0;
    display: flex;
    list-style: none;
    padding: 0 1rem;
    position: relative;
    align-items: center;
    height: 100%;
`;

export const NavItem = styled.li<{ $isOpen: boolean }>`
    ${({ $isOpen, theme }) => css`
        padding: 0;
        margin: 0;
        height: 100%;
        border-bottom: 0.1875rem solid transparent;
        display: flex;
        justify-content: center;
        max-height: 3.5rem;

        ${$isOpen &&
        css`
            border-bottom-color: ${theme.colors.interactive.hex};
        `}
    `}
`;

export const Button = styled.button`
    ${({ theme }) => css`
        background: transparent;
        border: none;
        font-size: 1.125rem;
        cursor: pointer;
        position: relative;
        padding: 0.5rem 0 0.375rem 0;
        margin: 0 1rem;
        font-weight: 300;

        ${ArrowIcon} {
            transform: translateY(0.125rem) rotate(90deg);
        }

        @media ${theme.breakpoints.huge.media} {
            font-size: 1.25rem;
        }

        @media ${theme.breakpoints.colossal.media} {
            margin: 0 1.25rem;
        }
    `}
`;

export const ArrowIcon = styled(Chevron)`
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    transform: translateY(0.125rem) rotate(90deg);
`;

export const NavigationIndicator = styled.span<{ $isOpen: boolean; $index: number }>`
    ${({ theme, $isOpen, $index }) => css`
        display: block;
        position: absolute;
        left: 50%;
        width: 1.5rem;
        height: 1.5rem;
        border-left: 1.5rem solid transparent;
        border-right: 1.5rem solid transparent;
        border-bottom: 1.5rem solid
            ${$index === 0
                ? theme.modules.siteNavigation.primaryNavigation.background
                : theme.modules.siteNavigation.background};
        transform: translateX(-50%);
        transition: none;
        opacity: 0;
        bottom: -3rem;
        pointer-events: none;

        ${$isOpen &&
        css`
            height: 1.5rem;
            opacity: 1;
            bottom: -2.25rem;
            transition: all 175ms ease 250ms;
        `}
    `}
`;

interface SubnavigationContainerProps {
    $isOpen: boolean;
    $isSticky: boolean;
}

export const SubnavigationContainer = styled(animated.div)<SubnavigationContainerProps>`
    ${({ theme, $isOpen, $isSticky }) => css`
        position: absolute;
        display: none;
        left: 50%;
        top: calc(100% - 2.625rem);
        width: 85vw;
        max-width: 87.5rem;
        transform-origin: top center;
        transform: translateX(-50%);
        z-index: 500;
        padding-top: 2rem;

        ${$isOpen &&
        css`
            display: block;
        `}

        ${$isSticky &&
        css`
            top: 100%;
        `}

        @media ${theme.breakpoints.huge.media} {
            left: 55%;
        }
    `}
`;
