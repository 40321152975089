import React, { useState } from 'react';
import { useTransition } from '@react-spring/web';
import { useTheme } from 'styled-components';
import useBreakpoint from 'Common/hooks/useBreakpoint';

import type { Props as SubnavigationProps } from 'Modules/SiteNavigation/subcomponents/Subnavigation';
import type { Props as PromotionProps } from '../Promotion';

import Promotion from '../Promotion';

import {
    Container,
    BackButton,
    BackIcon,
    Inner,
    View,
    PrimaryList,
    Item,
    Icon,
    SecondaryNavigationButton,
    SecondaryNavigationButtonIcon,
    SecondaryView,
    SecondaryHeading,
    SecondaryList,
    Tag
} from './Subnavigation.styled';
import { Link } from 'Modules/SiteNavigation/subcomponents/Subnavigation/Subnavigation.styled';

export interface Props extends Omit<SubnavigationProps, 'promotion'> {
    closeFnc: () => void;
    promotion?: PromotionProps;
}

enum Views {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

const Subnavigation: React.FC<Props> = props => {
    const { closeFnc, label, primaryNavigation, promotion, secondaryNavigation } = props;
    const [view, setView] = useState<Views>(Views.PRIMARY);
    const { breakpoints } = useTheme();
    const isMediumBreakpoint = useBreakpoint(breakpoints.medium.value);

    const secondaryViewTransition = useTransition(isMediumBreakpoint || view === Views.SECONDARY, {
        from: { left: '100%' },
        enter: { left: '0%' },
        leave: { left: '100%' },
        config: {
            tension: 135,
            friction: 20
        }
    });

    return (
        <Container>
            <BackButton
                onClick={() => (view === Views.PRIMARY ? closeFnc() : setView(Views.PRIMARY))}
                aria-label="Go back"
            >
                <BackIcon />
                {label}
            </BackButton>
            <Inner>
                {view === Views.PRIMARY && (
                    <View>
                        <nav aria-label={`${label} main navigation`}>
                            <PrimaryList>
                                {primaryNavigation.links.map(
                                    ({ label, id, icon, iconColor, ...rest }) => (
                                        <Item key={id}>
                                            <Link {...rest}>
                                                <Icon as={icon} $iconColor={iconColor} />
                                                {label}
                                            </Link>
                                        </Item>
                                    )
                                )}
                            </PrimaryList>
                        </nav>
                        <SecondaryNavigationButton
                            onClick={() => setView(Views.SECONDARY)}
                            aria-label={`View more ${label} links`}
                        >
                            More
                            <SecondaryNavigationButtonIcon />
                        </SecondaryNavigationButton>
                    </View>
                )}
                {secondaryViewTransition(
                    (styles, visible) =>
                        visible && (
                            <SecondaryView style={styles} aria-hidden={!visible}>
                                <nav>
                                    <SecondaryHeading aria-label={`${label} secondary navigation`}>
                                        {secondaryNavigation.heading}
                                    </SecondaryHeading>
                                    <SecondaryList>
                                        {secondaryNavigation.links.map(
                                            ({ id, tag, label, ...rest }) => (
                                                <Item key={id}>
                                                    <Link {...rest}>
                                                        {label}
                                                        {tag && <Tag $type={tag}>{tag}</Tag>}
                                                    </Link>
                                                </Item>
                                            )
                                        )}
                                    </SecondaryList>
                                </nav>
                            </SecondaryView>
                        )
                )}
            </Inner>
            {promotion && <Promotion {...promotion} />}
        </Container>
    );
};

export default Subnavigation;
