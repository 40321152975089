import styled, { css } from 'styled-components';
import type { ButtonVariants } from 'Theme/types/elements.type';

export const Container = styled.button<{ $variant: ButtonVariants }>`
    ${({ theme, $variant }) => css`
        --button-font-size: 1.125rem;
        --button-padding-x: 0.75rem;
        --button-padding-y: 0.875rem;

        font-size: var(--button-font-size);
        padding: var(--button-padding-y) var(--button-padding-x);
        font-weight: 700;
        letter-spacing: 0;
        line-height: 1.2;
        border-radius: 0.5rem;
        text-decoration: none;
        transition: background-color 200ms ease;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        background: ${theme.elements.button[$variant].background};
        border: ${theme.elements.button[$variant].border};
        color: ${theme.elements.button[$variant].color};
        transition: all 250ms ease;

        &:hover {
            background: ${theme.elements.button[$variant].hover?.background};
            color: ${theme.elements.button[$variant].hover?.color};
            cursor: pointer;
        }

        @media ${theme.breakpoints.large.media} {
            --button-font-size: 1.5rem;
            --button-padding-x: 1rem;
        }
    `}
`;
