import styled, { css } from 'styled-components';

import BorderIcon from 'Elements/Icon/Border';
import { Link as BaseLink } from '../Masthead.styled';

export const BodyOverlay = styled.div`
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

export const Container = styled.div`
    ${({ theme }) => css`
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: ${theme.colors.tertiary.hex};
        color: ${theme.colors.secondary.hex};
        padding: 4.75rem 4rem;
        z-index: 1000;
    `}
`;

export const CloseButton = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: inherit;
`;

export const Heading = styled.div`
    font-size: 1.5rem;
    font-weight: 200;
    margin-bottom: 2.25rem;
`;

export const NavList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const NavItem = styled.li`
    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
`;

export const Link = styled(BaseLink)`
    && {
        justify-content: space-between;
        font-size: 1.25rem;
        font-weight: 200;
        padding: 0;
    }
`;

export const Icon = styled(BorderIcon)`
    width: 1.75rem;
    height: 1.75rem;
`;
