import styled, { css } from 'styled-components';

import { ReactComponent as WaveSVG } from 'Public/graphics/waves/open-days/contact.svg';

import Section from 'Elements/Section';
import Link from 'Elements/Link';
import ArrowButton from 'Elements/Button/Arrow';
import Icon from 'Elements/Icon';

export const Container = styled(Section)`
    position: relative;
    overflow-x: clip;
`;

export const StyledIcon = styled(Icon)`
    ${({ theme }) => css`
        width: 4rem;
        height: 4rem;
        color: ${theme.colors.primary.hex};
        transform: rotate(-25deg);

        @media ${theme.breakpoints.medium.media} {
            width: 5.5rem;
            height: 5.5rem;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        margin: 0;
        font-size: 2rem;
        line-height: 1.35;
        font-weight: 700;

        @media ${theme.breakpoints.large.media} {
            font-size: 3rem;
        }
    `}
`;

export const StyledLink = styled(Link)`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        font-size: 1.75rem;
        margin-top: 1rem;
        display: block;

        @media ${theme.breakpoints.medium.media} {
            font-size: 2.5rem;
        }
    `}
`;

export const StyledArrowButton = styled(ArrowButton)`
    margin-top: 1.5rem;
`;

export const SocialsWrapper = styled.div`
    ${({ theme }) => css`
        margin-top: 2rem;

        @media ${theme.breakpoints.large.media} {
            margin-top: 3.5rem;
        }
    `}
`;

export const Wave = styled(WaveSVG)`
    ${({ theme }) => css`
        position: absolute;
        z-index: -1;
        bottom: -5rem;
        left: -102rem;
        width: 215rem;
        height: 42rem;

        @media ${theme.breakpoints.large.media} {
            bottom: -22rem;
            left: -204rem;
            width: 425rem;
            height: 85rem;
        }
    `}
`;
