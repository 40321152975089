import styled, { css } from 'styled-components';

export const Container = styled.div`
    height: 2.25rem;
    background-color: ${({ theme }) => theme.colors.secondary.tints[0].hex};
    display: flex;
    justify-content: flex-end;
`;
export const Wrapper = styled.div`
    min-width: 100rem;
    margin: 0 auto;
`;

export const Navigation = styled.nav`
    height: 100%;
`;

export const NavList = styled.ul`
    list-style: none;
    padding: 0;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
`;

export const NavItem = styled.li<{ $isActive?: boolean }>`
    ${({ theme, $isActive }) => {
        const { navItem } = theme.modules.masthead;

        return css`
            font-size: 1rem;
            height: 100%;
            margin: 0;
            background: ${navItem.background.neutral};
            display: none;

            &:not(:last-child) {
                border-right: 0.0625rem solid ${navItem.borderColor};
            }

            &:hover {
                background: ${navItem.background.hover};
            }

            ${$isActive &&
            css`
                background: ${navItem.background.active};
                display: block;
            `}

            @media ${theme.breakpoints.medium.media} {
                display: block;
            }
        `;
    }}
`;

// use next link
export const Link = styled.a`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: inherit;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0 1.5rem;

        @media ${theme.breakpoints.large.media} {
            padding: 0 1.75rem;
            font-size: 1rem;
        }
    `}
`;

export const ViewMore = styled(NavItem)`
    display: block;
`;

export const ViewMoreButton = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    margin: 0;
    padding: 0.25rem 1.5rem;
    width: calc(1.75rem + 3rem);
    height: calc(1.75rem + 0.5rem);
`;
