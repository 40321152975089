import styled, { css } from 'styled-components';
import NextLink from 'next/link';

import type { LinkVariants } from 'Theme/types/elements.type';

interface ContainerProps {
    $variant?: LinkVariants;
    $bold?: boolean;
}

const linkCSS = ({ theme, $variant, $bold }) => css`
    ${$variant &&
    css`
        color: ${theme.elements.link[$variant].color};
    `}

    ${$bold &&
    css`
        font-weight: 600;
    `}
`;

export const Container = styled(NextLink)<ContainerProps>`
    ${({ theme, $variant, $bold }) => css`
        ${linkCSS({ theme, $variant, $bold })}
    `}
`;

export const StandardLink = styled.a<ContainerProps>`
    ${({ theme, $variant, $bold }) => css`
        ${linkCSS({ theme, $variant, $bold })}
    `}
`;
