import { BenCardProps } from 'Components/Card/Ben';
import { BenVideoCardProps } from 'Components/Card/Ben/Video';
import { BenEventCardProps } from 'Components/Card/Ben/Event';
import { ListingCardProps } from 'Components/Card/Listing';
import { ClearingCardProps } from 'Campaigns/Clearing/Card';

export type CardProps =
    | BenCardProps
    | BenVideoCardProps
    | BenEventCardProps
    | ListingCardProps
    | ClearingCardProps;

export const isBENCard = (card: CardProps): card is BenCardProps => {
    return 'description' in card && !('button' in card);
};

export const isVideoCard = (card: CardProps): card is BenVideoCardProps => {
    return 'length' in card;
};

export const isEventCard = (card: CardProps): card is BenEventCardProps => {
    return 'location' in card && !('description' in card);
};

export const isListingCard = (card: CardProps): card is ListingCardProps => {
    return 'button' in card && !('preHeading' in card);
};

export const isClearingCard = (card: CardProps): card is ClearingCardProps => {
    return 'preHeading' in card;
};

export const getSlugFromContentType = (contentType: string): string => {
    let slug = '';

    switch (contentType) {
        case 'blogArticle':
            slug = 'blogs';
            break;
        case 'event':
            slug = 'events';
            break;
        case 'videoArticle':
            slug = 'videos';
            break;
        default:
            slug = 'news';
            break;
    }

    return slug;
};

export const getContentTypeFromSlug = (slug: string): string => {
    let contentType = '';

    switch (slug) {
        case 'blogs':
            contentType = 'blogArticle';
            break;
        case 'events':
            contentType = 'event';
            break;
        case 'videos':
            contentType = 'videoArticle';
            break;
        default:
            contentType = 'newsArticle';
            break;
    }

    return contentType;
};
