import React from 'react';

import Modal, { ModalProps } from 'Components/Modal';

import IconButton, { IconButtonProps } from 'Campaigns/Clearing/Button/Icon';

import { Heading, ButtonsWrapper } from './ApplyModal.styled';

export interface Props {
    modal: ModalProps;
    heading: string;
    ukApply: IconButtonProps;
    internationalApply: IconButtonProps;
}

const ApplyModal: React.FC<Props> = props => {
    const { modal, heading, ukApply, internationalApply } = props;

    return (
        <Modal {...modal} customWidth="fit-content">
            <>
                <Heading>{heading}</Heading>
                <ButtonsWrapper>
                    <IconButton {...ukApply} />
                    <IconButton {...internationalApply} />
                </ButtonsWrapper>
            </>
        </Modal>
    );
};

export default ApplyModal;
