import React from 'react';

import { IconButtonProps } from 'Elements/Button/Icon';

import { StyledIconButton } from './Icon.styled';

export type ClearingIconButtonVariants =
    | 'default'
    | 'white'
    | 'large'
    | 'siteBanner'
    | 'whiteSiteBanner';

export interface Props extends Omit<IconButtonProps, 'variant' | 'type'> {
    variant?: ClearingIconButtonVariants;
    onClick?: () => void;
}

const Icon: React.FC<Props> = props => {
    const { href, variant, onClick, ...rest } = props;

    return (
        <StyledIconButton
            {...rest}
            href={!onClick ? href : undefined}
            $variant={variant}
            onClick={onClick}
        />
    );
};

export default Icon;
