import styled, { css } from 'styled-components';
import CSS from 'csstype';

import { Wrapper as Grid } from 'Components/Grid/Grid.styled';
import { Wrapper as Col } from 'Components/Grid/Col/Col.styled';

import type { Padding, PaddingWithValue } from './Section';

interface WrapperProps {
    $padding?: Padding;
    $paddingTop?: PaddingWithValue;
    $paddingBottom?: PaddingWithValue;
    $align?: 'left' | 'center' | 'right';
    $overflow?: CSS.Property.Overflow;
    $colour?: CSS.Property.Color;
}

export const Wrapper = styled.section<WrapperProps>`
    ${({ theme, $padding, $paddingTop, $paddingBottom, $align, $overflow, $colour }) => css`
        max-width: 100%;
        position: relative;
        text-align: ${$align};
        overflow: ${$overflow};
        color: ${$colour};

        --default-padding: 1.75rem;

        @media ${theme.breakpoints.large.media} {
            --default-padding: 3rem;
        }

        && {
            ${$padding !== 'none' &&
            css`
                ${$paddingTop !== 'none' &&
                (typeof $paddingTop === 'number'
                    ? css`
                          padding-top: ${$paddingTop * 2}rem;

                          @media ${theme.breakpoints.large.media} {
                              padding-top: ${$paddingTop * 3}rem;
                          }
                      `
                    : css`
                          padding-top: var(--default-padding);

                          section + & {
                              padding-top: 0;
                          }
                      `)}
                ${$paddingBottom !== 'none' &&
                (typeof $paddingBottom === 'number'
                    ? css`
                          padding-bottom: ${$paddingBottom * 2}rem;

                          @media ${theme.breakpoints.large.media} {
                              padding-bottom: ${$paddingBottom * 3}rem;
                          }
                      `
                    : css`
                          padding-bottom: var(--default-padding);
                      `)}
            `}
        }

        & > ${Grid} > ${Col} > *:first-child,
        & > *:first-child {
            margin-top: 0;
        }
    `}
`;

interface BackgroundProps {
    $backgroundStart?: boolean;
    $backgroundStartTop?: string;
    $background?: boolean;
    $backgroundEnd?: boolean;
    $backgroundColor?: string;
    $backgroundTop?: string;
    $shape: 'box' | 'circle';
    $gradient?: boolean;
    $top?: 'string';
}

export const Background = styled.span<BackgroundProps>`
    ${({
        $shape,
        $gradient,
        $backgroundStart,
        $backgroundStartTop = '50%',
        $background,
        $backgroundEnd,
        $backgroundColor,
        $backgroundTop,
        theme
    }) => css`
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;

        &:before {
            content: '';
            position: absolute;
            width: 200%;
            left: 50%;
            height: calc(50% ${$backgroundStart ? '-' : '+'} ${$backgroundTop || '0rem'});
            background: ${$gradient
                ? `linear-gradient(to ${$backgroundStart ? 'top' : 'bottom'}, ${
                      $backgroundColor
                          ? `${$backgroundColor}, ${theme.colors.background.hex}`
                          : theme.gradients.accent.five
                  })`
                : $backgroundColor
                ? $backgroundColor
                : theme.colors.secondary.tints[1].hex};

            ${$backgroundTop
                ? css`
                      transform: translate(-50%, ${$backgroundTop});
                  `
                : css`
                      transform: translateX(-50%);
                  `}

            ${$background &&
            css`
                top: 0;
                bottom: 0;
                height: 100%;
            `}

            ${$backgroundStart &&
            css`
                top: ${$backgroundStartTop};
            `}

            ${$backgroundEnd &&
            css`
                top: 0;
            `}

            ${$backgroundStart &&
            $shape === 'circle' &&
            css`
                border-radius: 50% 50% 0 0;
            `}

            ${$backgroundEnd &&
            $shape === 'circle' &&
            css`
                border-radius: 0 0 50% 50%;
            `}

            @media ${theme.breakpoints.large.media} {
                width: 110%;
            }
        }
    `}
`;
