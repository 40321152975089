import React from 'react';
import type { ButtonVariants } from 'Theme/types/elements.type';

import { Container } from './Button.styled';

import Link from 'Elements/Link';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    ariaLabel?: string;
    label?: string;
    href?: string;
    prefetch?: boolean;
    variant?: ButtonVariants;
    type?: 'button' | 'submit' | 'reset';
}

export interface LinkButtonProps extends Props {
    href: string;
    openInNewTab?: boolean;
    rel?: string;
}

const Button: React.FC<Props> = props => {
    const {
        label,
        ariaLabel,
        prefetch,
        href,
        type,
        variant = 'default',
        children,
        className = '',
        ...rest
    } = props;

    return href ? (
        <Container
            as={Link}
            href={href}
            ariaLabel={ariaLabel}
            className={`button button-link ${className}`.trim()}
            $variant={variant}
            prefetch={prefetch && prefetch}
            {...rest}
        >
            {label ? label : children}
        </Container>
    ) : (
        <Container
            aria-label={ariaLabel}
            $variant={variant}
            type={type}
            className={`button ${className}`.trim()}
            {...rest}
        >
            {label ? label : children}
        </Container>
    );
};

export default Button;
