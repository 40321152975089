import React from 'react';

import type { Site } from '../Masthead';

import { Chevron, Cross } from '@solent-university/solent-icons';

import {
    BodyOverlay,
    Container,
    CloseButton,
    Heading,
    NavList,
    NavItem,
    Link,
    Icon
} from './Modal.styled';

interface Props {
    sites: Site[];
    closeModalFnc: () => void;
}

const Modal: React.FC<Props> = props => {
    const { sites, closeModalFnc } = props;

    return (
        <>
            <BodyOverlay onClick={closeModalFnc} />
            <Container>
                <CloseButton onClick={closeModalFnc}>
                    <Icon icon={Cross} />
                </CloseButton>
                <Heading>More Solent sites</Heading>
                <nav>
                    <NavList>
                        {sites.map(site => (
                            <NavItem key={site.id}>
                                <Link
                                    href={site.link.href}
                                    aria-label={site.link.ariaLabel}
                                    target="_blank"
                                    rel="noopener nofollow"
                                >
                                    {site.name}
                                    <Icon icon={Chevron} />
                                </Link>
                            </NavItem>
                        ))}
                    </NavList>
                </nav>
            </Container>
        </>
    );
};

export default Modal;
