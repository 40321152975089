import { useState, useEffect } from 'react';
import useViewport from './useViewport';

const useBreakpoint = (
    breakpoint: number,
    options: {
        default?: boolean;
        type?: 'width' | 'height';
        orientation?: null | 'landscape' | 'portrait';
    } = {}
): boolean => {
    const { type = 'width', orientation = null } = options;
    const [isBreakpoint, setIsBreakpoint] = useState(false);
    const { width, height, orientation: viewportOrientation } = useViewport();

    useEffect(() => {
        if (orientation) {
            if (orientation !== viewportOrientation) {
                setIsBreakpoint(false);
            }
        }

        setIsBreakpoint(type === 'width' ? width >= breakpoint : height >= breakpoint);
    }, [width, height]);

    return isBreakpoint;
};

export default useBreakpoint;
