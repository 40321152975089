import styled, { css } from 'styled-components';

export const RelativeWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        background: ${theme.colors.secondary.tints[1].hex};
        width: 100%;
        overflow: hidden;
        padding: 0.75rem 0;
        border-bottom: ${theme.commonStyles.brandPixel} solid ${theme.colors.secondary.tints[3].hex};
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        gap: 1.5rem;

        @media ${theme.breakpoints.xlarge.media} {
            padding-left: 10rem;
        }

        @media ${theme.breakpoints.huge.media} {
            padding-left: 7rem;
        }

        @media ${theme.breakpoints.giant.media} {
            padding-left: 2rem;
        }
    `}
`;

export const ListWrapper = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0;
    margin: 0;
`;

export const StatusTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ClearingStatusText = styled.p`
    ${({ theme }) => css`
        font-size: 1rem;
        line-height: 1.2;
        font-weight: 700;
        margin: 0;

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.25rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            font-size: 1.5rem;
        }
    `}
`;

export const RedText = styled.span`
    ${({ theme }) => css`
        color: ${theme.colors.campaigns.clearing.primary.hex};
    `}
`;

export const StyledListItem = styled.li`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: list-item;
        }
    `}
`;

export const ColWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        gap: 1rem;

        @media ${theme.breakpoints.medium.media} {
            gap: 5rem;
        }
    `}
`;
