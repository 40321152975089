import { useRef, useEffect } from 'react';

const usePrevious = (value: unknown): unknown => {
    const ref = useRef<unknown | undefined>(undefined);

    // TODO write tests
    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export default usePrevious;
