import React from 'react';
import type CSS from 'csstype';
import { useTheme } from 'styled-components';

import { Wrapper } from './Col.styled';

import type { BreakpointShortNames } from 'Theme/types/breakpoints.type';

export type Span = number | [start: number, end: number];

export type Spans = Partial<Record<BreakpointShortNames, Span>>;

export interface Props extends Spans {
    align?: CSS.Property.AlignSelf;
    justify?: CSS.Property.JustifySelf;
    overflow?: CSS.Property.Overflow;
    className?: string;
    position?: CSS.Property.Position;
    textAlign?: CSS.Property.TextAlign;
    order?: CSS.Property.Order;
}

const Col: React.FC<Props> = props => {
    const { align, justify, overflow, className, position, textAlign, order, children, ...spans } =
        props;
    const { breakpoints } = useTheme();

    // Orders the defined spans based on width value
    const orderedSpansArray = Object.keys(spans)
        .filter((key: string) => spans[key])
        .map((key: string) => {
            const breakpointKey =
                Object.keys(breakpoints).find(
                    breakpointKey => breakpoints[breakpointKey].shortName === key
                ) || key;

            return {
                key,
                value: spans[key],
                breakpoint: breakpoints[breakpointKey] || undefined
            };
        })
        .sort((a, b) => (a.breakpoint?.value < b.breakpoint?.value ? -1 : 1));

    return (
        <Wrapper
            $spans={orderedSpansArray}
            $align={align}
            $justify={justify}
            $overflow={overflow}
            $position={position}
            $textAlign={textAlign}
            $order={order}
            className={className}
        >
            {children}
        </Wrapper>
    );
};

export default Col;
