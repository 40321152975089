import React from 'react';

import { Chevron } from '@solent-university/solent-icons';

import { SkipButton, Icon } from './ContentSkip.styled';

const ContentSkip: React.FC = () => {
    return (
        <SkipButton href="#main">
            Skip to main content <Icon icon={Chevron} />
        </SkipButton>
    );
};

export default ContentSkip;
