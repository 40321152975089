import React, { useRef, useState, useEffect } from 'react';

interface Args {
    defaultSticky?: boolean;
    disabled?: boolean;
}

const useSticky = <ElementType extends HTMLElement>(
    args: Args = {}
): {
    ref: React.RefObject<ElementType>;
    isSticky: boolean;
    stickyProps: {
        disabled: boolean;
        innerRef: React.RefObject<HTMLDivElement>;
        isSticky: boolean;
        height: number;
    };
    Sticky: React.FC;
} => {
    const { defaultSticky = false, disabled = false } = args;
    const [isSticky, setIsSticky] = useState(defaultSticky);
    const [height, setHeight] = useState<number>(0);
    const ref = useRef() as React.RefObject<ElementType>;
    const wrapperRef = useRef() as React.RefObject<HTMLDivElement>;

    const getTop = (element: HTMLElement): number => {
        return window.scrollY + element.getBoundingClientRect().top;
    };

    const handleScroll = () => {
        if (!ref.current || !wrapperRef.current || disabled) return;

        const { scrollY } = window;
        const top = getTop(wrapperRef.current);

        const existingStickyElements = [
            ...document.querySelectorAll('[data-is-sticky]')
        ] as HTMLElement[];

        const stickyElements = existingStickyElements.map(element => ({
            top: getTop(element)
        }));

        if (
            stickyElements.some(
                element => element.top && scrollY > element.top && element.top > top
            )
        ) {
            setIsSticky(false);
        } else {
            if (top > window.scrollY) {
                isSticky && setIsSticky(false);
            } else if (top < window.scrollY) {
                !isSticky && setIsSticky(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isSticky, disabled]);

    useEffect(() => {
        if (ref.current && ref.current.offsetHeight !== 0) {
            setHeight(ref.current.offsetHeight);
        }
    }, [ref.current?.offsetHeight]);

    return {
        ref,
        isSticky: disabled ? false : isSticky,
        stickyProps: { disabled, innerRef: wrapperRef, isSticky, height },
        Sticky: Wrapper
    };
};

interface Props {
    disabled: boolean;
    innerRef: React.RefObject<HTMLDivElement>;
    isSticky: boolean;
    height: number;
    children: React.ReactNode;
}

const Wrapper: React.FC<Props> = ({ disabled, innerRef, isSticky, height, children }) => (
    <div
        ref={innerRef}
        style={{
            height: isSticky ? height + 'px' : undefined
        }}
        data-is-sticky={disabled ? undefined : true}
        children={children}
    />
);

export default useSticky;
