import styled, { css } from 'styled-components';
import type { ButtonVariants } from 'Theme/types/elements.type';
import { colord } from 'colord';

import { Chevron } from '@solent-university/solent-icons';
import Button from '../Button';

export const Container = styled(Button)<{ $variant: ButtonVariants }>`
    --button-padding-hover-x: 1.25rem;
    --icon-background-size: 1.5rem;

    ${({ theme, $variant }) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: calc((var(--button-padding-hover-x) - var(--button-padding-x)));

        &&:hover {
            background: ${theme.elements.button[$variant].background};
            color: ${theme.elements.button[$variant].color};
            border-radius: 2rem;
        }

        @media ${theme.breakpoints.large.media} {
            --icon-background-size: 2rem;
        }
    `}
`;

export const IconContainer = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: var(--icon-background-size);
    /* Defined in ./button.styled.tsx */
    height: var(--button-font-size);
    overflow: visible;
    transition: transform 200ms ease;
    margin-left: 0.5rem;

    ${Container}:hover & {
        transform: translateX(0.35rem);
    }
`;

export const IconBackground = styled.span<{ $variant: ButtonVariants }>`
    background: currentColor;
    border-radius: 50%;
    width: var(--icon-background-size);
    height: var(--icon-background-size);
`;

export const ArrowIcon = styled(Chevron)<{ $variant: ButtonVariants }>`
    ${({ theme, $variant }) => css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-45%, -50%);
        /* 60% of --icon-background-size */
        width: calc((var(--icon-background-size) / 100) * 60);
        height: calc((var(--icon-background-size) / 100) * 60);
        /* 
        Check if the background is transparent. 
        If true, set to interactive is color is light, or interactiveContrast if dark. 
        If background isn't transparent, set the icon color to that 
        */
        color: ${theme.elements.button[$variant].background === 'transparent'
            ? colord(theme.elements.button[$variant].color).isLight()
                ? theme.colors.interactive.hex
                : theme.colors.interactiveContrast.hex
            : theme.elements.button[$variant].background};
    `}
`;
