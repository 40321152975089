import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';
import { Chevron } from '@solent-university/solent-icons';

import { SiteAlertVariants } from 'Theme/types/components.type';

import ArrowLink from 'Elements/Link/Arrow';

export const Container = styled.div<{ $variant: SiteAlertVariants }>`
    ${({ theme, $variant }) => css`
        border: 0.125rem solid ${theme.components.siteAlert[$variant].backgroundColour};
        border-top: none;
    `}
`;

export const Button = styled.button<{ $variant: SiteAlertVariants }>`
    ${({ theme, $variant }) => css`
        background-color: ${theme.components.siteAlert[$variant].backgroundColour};
        color: ${theme.components.siteAlert[$variant].textColour};
        width: 100%;
        border: none;
        cursor: pointer;

        @media ${theme.breakpoints.large.media} {
            padding: 0 1.5rem;
        }
    `}
`;

export const ButtonInner = styled.span`
    ${({ theme }) => css`
        max-width: 100%;
        width: 85.5rem;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 2.5rem;
        position: relative;

        @media ${theme.breakpoints.large.media} {
            justify-content: center;
        }
    `}
`;

export const StyledIcon = styled.svg`
    flex: 0 0 1.875rem;
    stroke-width: 0.125rem;
`;

export const ButtonText = styled.span`
    ${({ theme }) => css`
        display: block;
        margin-left: 1rem;
        font-weight: 700;
        padding: 0.25rem 3.25rem 0.25rem 0;
        text-align: left;
        font-size: 1.125rem;
        max-width: 57.75rem;

        @media ${theme.breakpoints.large.media} {
            text-align: center;
            font-size: 1.25rem;
        }
    `}
`;

export const ArrowWrapper = styled.span`
    ${({ theme }) => css`
        position: absolute;
        top: 50%;
        right: 0;
        height: 2.75rem;
        width: 2.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-50%);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: ${theme.colors.primaryContrast.hex};
            opacity: 0.3;
        }
    `}
`;

export const Arrow = styled(Chevron)<{ $isExpanded: boolean }>`
    ${({ $isExpanded }) => css`
        width: 1rem;
        height: 1rem;
        transform: rotate(90deg);
        transition-duration: 0.25s;

        ${$isExpanded &&
        css`
            transform: rotate(-90deg);
        `}
    `}
`;

export const AnimatedInner = styled(animated.div)`
    overflow: hidden;
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        max-width: 68.75rem;
        padding: 0.75rem 1.5rem;
        margin: 0 auto;

        p:first-of-type {
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        @media ${theme.breakpoints.large.media} {
            text-align: center;
        }
    `}
`;

export const StyledArrowLink = styled(ArrowLink)<{ $variant: SiteAlertVariants }>`
    ${({ theme, $variant }) => css`
        margin-top: 1rem;
        color: ${theme.components.siteAlert[$variant].linkColour};
    `}
`;
