import styled, { css } from 'styled-components';
import { colord } from 'colord';

import type { ClearingIconButtonVariants } from './Icon';

import IconButton from 'Elements/Button/Icon';
import { Icon } from 'Elements/Button/Icon/Icon.styled';

export const StyledIconButton = styled(IconButton)<{
    $variant?: ClearingIconButtonVariants;
}>`
    ${({ theme, $variant }) => css`
        && {
            font-size: 1rem;
            display: inline-flex;
            flex-direction: row-reverse;
            justify-content: center;
            background: ${theme.colors.campaigns.clearing.primary.hex};
            color: ${theme.colors.primaryContrast.hex};
            border-radius: 0.25rem;
            padding: 0.75rem 0.5rem 0.75rem 0.125rem;
            border: none;
            height: 2rem;

            &:hover {
                background: ${colord(theme.colors.campaigns.clearing.primary.hex)
                    .darken(0.1)
                    .toHex()};
            }

            @media ${theme.breakpoints.medium.media} {
                gap: 0.25rem;
            }

            @media ${theme.breakpoints.xlarge.media} {
                font-size: 1.125rem;
                padding: 0.75rem 0.75rem 0.75rem 0.25rem;
                gap: 0.375rem;
                height: 2.5rem;
            }

            ${Icon} {
                color: ${theme.colors.primaryContrast.hex};
                width: auto;
                height: 1.25rem;
                margin-left: 0;
                stroke-width: 0.125rem;
            }

            ${$variant === 'white' &&
            css`
                color: ${theme.colors.secondary.tints[8].hex};
                background: ${theme.colors.campaigns.clearing.primaryContrast.hex};
                border: ${theme.commonStyles.brandPixel} solid
                    ${theme.colors.secondary.tints[8].hex};

                ${Icon} {
                    color: ${theme.colors.secondary.tints[8].hex};
                }

                &:hover {
                    background: ${colord(theme.colors.campaigns.clearing.primaryContrast.hex)
                        .darken(0.1)
                        .toHex()};
                    color: ${theme.colors.secondary.tints[8].hex};
                }
            `}

            ${$variant === 'large' &&
            css`
                width: auto;
                height: 3rem;
                min-width: 9.125rem;

                @media ${theme.breakpoints.xlarge.media} {
                    font-size: 1.5rem;
                    width: auto;
                    height: 3rem;
                }
            `}

            ${$variant === 'siteBanner' &&
            css`
                height: 2.5rem;
                min-width: 9.75rem;
            `}

            ${$variant === 'whiteSiteBanner' &&
            css`
                color: ${theme.colors.secondary.tints[8].hex};
                background: ${theme.colors.campaigns.clearing.primaryContrast.hex};
                border: ${theme.commonStyles.brandPixel} solid
                    ${theme.colors.secondary.tints[8].hex};
                height: 2.5rem;

                ${Icon} {
                    color: ${theme.colors.secondary.tints[8].hex};
                }

                &:hover {
                    background: ${colord(theme.colors.campaigns.clearing.primaryContrast.hex)
                        .darken(0.1)
                        .toHex()};
                    color: ${theme.colors.secondary.tints[8].hex};
                }
            `}
        }
    `}
`;
