import React from 'react';

import { Container, Text } from './PreviewStatus.styled';

const PreviewStatus: React.FC = () => {
    return (
        <Container>
            <Text>Preview mode</Text>
        </Container>
    );
};

export default PreviewStatus;
