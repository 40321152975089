import styled, { css } from 'styled-components';
import type CSS from 'csstype';

interface WrapperProps {
    $layout?: 'fill' | 'content';
    $rowGap: CSS.Property.RowGap<string>;
    $position?: CSS.Property.Position;
}

export const Wrapper = styled.div<WrapperProps>`
    ${({ theme, $layout, $rowGap, $position }) => css`
        position: ${$position ? $position : 'relative'};
        display: grid;
        max-width: 82.5rem;
        grid-template-columns: repeat(12, 1fr);
        margin: 0 0.75rem;
        column-gap: 0.75rem;

        ${Wrapper} & {
            margin: 0;
        }

        ${$rowGap &&
        css`
            row-gap: ${$rowGap};
        `}

        @media ${theme.breakpoints.large.media} {
            margin: 0 auto;
            padding: 0 1.5rem;
            column-gap: 1.5rem;
            max-width: calc(82.5rem + 3rem);

            ${Wrapper} & {
                padding: 0;
            }
        }

        ${$layout === 'fill' &&
        css`
            height: 100%;
            width: calc(100% - 1.5rem);
        `}
    `}
`;
