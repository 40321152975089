import styled, { css, createGlobalStyle } from 'styled-components';
import scrollbarStyle from 'Common/styles/scrollbar';

import IconBorder from 'Elements/Icon/Border/Border';

export const BodyFixed = createGlobalStyle`
    html, body {
        overflow: hidden;
        position: relative;
    }
`;

export const ModalStyled = styled.div`
    ${({ theme }) => css`
        min-height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1500;
        background-color: ${theme.colors.modalOverlay.hex};
    `}
`;

export const Inner = styled.div<{ $customwidth?: string }>`
    ${({ theme, $customwidth }) => css`
        ${scrollbarStyle()}
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100vw - 3rem);
        background-color: white;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
        max-width: 87.5rem;
        max-height: 90%;
        overflow-y: auto;

        @media ${theme.breakpoints.medium.media} {
            padding: 2rem 2.5rem;

            ${$customwidth &&
            css`
                max-width: ${$customwidth};
            `}
        }
    `}
`;

export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        border: none;
        padding: 0;
        background: transparent;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primary.hex};
        align-self: flex-end;

        svg {
            stroke-width: ${theme.commonStyles.brandPixel};
        }

        @media ${theme.breakpoints.medium.media} {
            top: 1rem;
            right: 1.75rem;
        }
    `}
`;

export const StyledIcon = styled(IconBorder)`
    width: 2rem;
    height: 2rem;
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        margin-top: 0;
        padding-right: 2rem;

        @media ${theme.breakpoints.medium.media} {
            padding-right: 5rem;
        }
    `}
`;

export const Description = styled.p`
    margin: 0 initial;
`;
