import React from 'react';
import NextImage, { ImageProps } from '../../../node_modules/next/image';
import type { StaticImageData } from '../../../node_modules/next/image';

const loader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
    return `${src}?w=${width}&q=${quality || 75}&f=webp`;
};

const Image: React.FC<ImageProps> = props => {
    const src = props.src as string | StaticImageData | { default: StaticImageData };
    const srcString = typeof src === 'string' ? src : 'src' in src ? src.src : src.default.src;

    if (srcString.includes('/cms')) {
        return <NextImage loader={loader} {...props} />;
    } else {
        return <NextImage {...props} />;
    }
};

export type { ImageProps } from '../../../node_modules/next/image';

export type { StaticImageData } from '../../../node_modules/next/image';

export default Image;
