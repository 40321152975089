import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999999;
    background-color: #1f4c9c;
    box-shadow: 0 0.25rem 0.25rem 0.125rem rgba(31, 76, 156, 0.59);
    margin: 0 0 0.5rem 0.5rem;
    border-radius: 1.5rem;
`;

export const Text = styled.span`
    display: block;
    color: white;
    padding: 0.75rem 1.25rem;
    line-height: 1;
    text-decoration: none;
    font-size: 1rem;
`;
