import styled, { css } from 'styled-components';

export const Heading = styled.h2`
    ${({ theme }) => css`
        font-size: 1.125rem;
        font-weight: 400;
        margin: 0 0 1rem;

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.25rem;
        }
    `}
`;

export const ButtonsWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding-bottom: 0.5rem;

        @media ${theme.breakpoints.medium.media} {
            flex-direction: row;
            gap: 2rem;
            padding-bottom: 0;
        }
    `}
`;
