import React from 'react';
import type { LinkVariants } from 'Theme/types/elements.type';

import { Container, StandardLink } from './Link.styled';

export interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
    ariaLabel?: string;
    label?: string;
    openInNewTab?: boolean;
    variant?: LinkVariants;
    bold?: boolean;
    withoutNextLink?: boolean;
    prefetch?: boolean;
}

const Link: React.FC<Props> = props => {
    const {
        ariaLabel,
        label,
        openInNewTab,
        variant,
        bold,
        children,
        href,
        className = '',
        withoutNextLink = false,
        prefetch,
        ...rest
    } = props;

    const linkProps = {
        'aria-label': ariaLabel,
        target: openInNewTab ? `_blank` : undefined,
        rel: openInNewTab ? 'noreferrer' : undefined,
        $variant: variant,
        $bold: bold,
        className: `link ${className}`.trim(),
        children: label ? label : children,
        ...rest
    };

    if (withoutNextLink) {
        return <StandardLink {...linkProps} href={href} />;
    }

    return <Container href={href} passHref prefetch={prefetch} {...linkProps} />;
};

export default Link;
