import React, { useState } from 'react';

import { landbot } from 'Layout/Main';

import Grid, { Col } from 'Components/Grid';

import IconLink, { IconButtonProps } from 'Campaigns/Clearing/Button/Icon';
import ApplyModal, { ApplyModalProps } from 'Campaigns/Clearing/ApplyModal';

import {
    RelativeWrapper,
    Inner,
    ListWrapper,
    StatusTextWrapper,
    ClearingStatusText,
    StyledListItem,
    RedText
} from './Banner.styled';

export interface Props {
    enableBanner?: boolean;
    isClearingOpen: boolean;
    clearingStatusText: string;
    clearingStatusTextRed?: string;
    contactLinks: (IconButtonProps & { id: string })[];
    highlightedCTA: IconButtonProps;
    preClearingCTA: IconButtonProps;
    enableApplicationModal: boolean;
    applyModal: Omit<ApplyModalProps, 'modal'>;
}

const Clearing: React.FC<Props> = props => {
    const {
        enableBanner,
        isClearingOpen,
        clearingStatusText,
        clearingStatusTextRed,
        contactLinks,
        highlightedCTA,
        preClearingCTA,
        enableApplicationModal,
        applyModal
    } = props;

    const [isApplyVisible, setIsApplyVisible] = useState<boolean>(false);

    const openChat = () => {
        landbot.open();
    };

    return enableBanner ? (
        <RelativeWrapper id="clearing-banner">
            <Grid>
                <Col align="center">
                    <Inner>
                        <StatusTextWrapper>
                            <ClearingStatusText>
                                {clearingStatusText}{' '}
                                {clearingStatusTextRed && (
                                    <RedText>{clearingStatusTextRed}</RedText>
                                )}
                            </ClearingStatusText>
                        </StatusTextWrapper>
                        {isClearingOpen ? (
                            <ListWrapper>
                                {contactLinks.map((item, index) => (
                                    <StyledListItem key={item.id}>
                                        <IconLink
                                            {...item}
                                            onClick={
                                                index === 0 && enableApplicationModal
                                                    ? () => setIsApplyVisible(true)
                                                    : index === 1
                                                    ? openChat
                                                    : undefined
                                            }
                                        />
                                    </StyledListItem>
                                ))}
                                <li key={highlightedCTA.id}>
                                    <IconLink
                                        {...highlightedCTA}
                                        onClick={
                                            highlightedCTA.label === 'Live chat' ||
                                            highlightedCTA.label === 'Start a chat'
                                                ? openChat
                                                : undefined
                                        }
                                    />
                                </li>
                                {enableApplicationModal && (
                                    <ApplyModal
                                        {...applyModal}
                                        modal={{
                                            id: 'clearing-apply',
                                            isVisible: isApplyVisible,
                                            closeFnc: () => setIsApplyVisible(false)
                                        }}
                                    />
                                )}
                            </ListWrapper>
                        ) : (
                            <IconLink {...preClearingCTA} />
                        )}
                    </Inner>
                </Col>
            </Grid>
        </RelativeWrapper>
    ) : null;
};

export default Clearing;
