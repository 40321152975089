import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import useBreakpoint from 'Common/hooks/useBreakpoint';

import { More } from '@solent-university/solent-icons';
import BorderIcon from 'Elements/Icon/Border';
import Modal from './subcomponents/Modal';

import {
    Container,
    Navigation,
    NavList,
    NavItem,
    Link,
    ViewMore,
    ViewMoreButton,
    Wrapper
} from './Masthead.styled';

export interface Site {
    id: string;
    link: {
        href: string;
        ariaLabel: string;
        nofollow?: boolean;
    };
    name: string;
    isActive?: boolean;
}

interface Props {
    sites: Site[];
}

const Masthead: React.FC<Props> = props => {
    const { sites } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { breakpoints } = useTheme();
    const isMediumBreakpoint = useBreakpoint(breakpoints.medium.value);

    useEffect(() => {
        if (isMediumBreakpoint) {
            setIsModalOpen(false);
        }
    }, [isMediumBreakpoint]);

    return (
        <Container>
            <Wrapper>
                <Navigation aria-label="Solent websites">
                    <NavList>
                        {sites.map(site => (
                            <NavItem key={site.id} $isActive={site.isActive}>
                                <Link
                                    href={site.link.href}
                                    aria-label={site.link.ariaLabel}
                                    target="_blank"
                                    rel={`noopener ${site.link.nofollow ? `nofollow` : ''}`}
                                >
                                    {site.name}
                                </Link>
                            </NavItem>
                        ))}
                        {!isMediumBreakpoint && (
                            <ViewMore>
                                <ViewMoreButton
                                    onClick={() => setIsModalOpen(true)}
                                    aria-label="View our websites"
                                >
                                    <BorderIcon icon={More} layout="fill" />
                                </ViewMoreButton>
                            </ViewMore>
                        )}
                    </NavList>
                    {isModalOpen && (
                        <Modal
                            sites={sites.filter(site => !site.isActive)}
                            closeModalFnc={() => setIsModalOpen(false)}
                        />
                    )}
                </Navigation>
            </Wrapper>
        </Container>
    );
};

export default Masthead;
