import React from 'react';
import type CSS from 'csstype';

import { Wrapper } from './Grid.styled';

interface Props {
    layout?: 'fill' | 'content';
    position?: CSS.Property.Position;
    style?: React.CSSProperties;
    // TODO setup rowgap to allow breakpoints
    rowGap?: CSS.Property.RowGap<string>;
}

const Grid: React.FC<Props> = props => {
    const { children, layout, style, rowGap, position } = props;

    return (
        <Wrapper $layout={layout} style={style} $rowGap={rowGap} $position={position}>
            {children}
        </Wrapper>
    );
};

export default Grid;
