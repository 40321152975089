import React from 'react';

import Head from 'next/head';

export interface Props {
    title: string;
    description: string;
    keywords: string;
    noIndex?: boolean;
    canonical?: string;
    path: string;
}

const Metadata: React.FC<Props> = props => {
    const { title, description, keywords, noIndex, canonical, path } = props;

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} key="description" />
            <meta name="keywords" content={keywords} key="keywords" />
            {noIndex && <meta name="robots" content="noindex" key="robots" />}
            <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
            <link rel="icon" type="image/png" href="/favicon.png" />
            {canonical && canonical !== '' ? (
                <link
                    rel="canonical"
                    href={
                        canonical.startsWith('http')
                            ? canonical
                            : `https://www.solent.ac.uk${canonical}`
                    }
                    key="canonical"
                />
            ) : (
                <link rel="canonical" href={`https://www.solent.ac.uk${path}`} key="canonical" />
            )}
        </Head>
    );
};

export default Metadata;
