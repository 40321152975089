import styled from 'styled-components';

export const Wrapper = styled.span`
    width: 2rem;
    height: 2rem;
    position: relative;
    display: block;

    @media ${({ theme }) => theme.breakpoints.large.media} {
        width: 2.5rem;
        height: 2.5rem;
    }
`;

export const Icon = styled.svg`
    max-width: 0.8em;
    max-height: 0.8em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
