import styled, { css } from 'styled-components';
import { Container as ArrowLink, IconContainer } from 'Elements/Link/Arrow/Arrow.styled';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        width: 100%;
        overflow: hidden;
        min-height: 6rem;
        height: 100%;
        position: relative;
        ${ArrowLink} {
            color: ${theme.colors.tertiary.hex};
        }

        ${IconContainer} {
            svg {
                color: ${theme.modules.siteNavigation.background};
            }
        }
    `}
`;

export const ImageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const Content = styled.div`
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
`;

export const Text = styled.div`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        font-size: 1.875rem;
        font-weight: 300;
        line-height: 1.1;
        color: ${({ theme }) => theme.colors.tertiary.hex};
    `}
`;
