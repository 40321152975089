import styled, { css } from 'styled-components';
import type { Span } from './Col';
import type { Breakpoint } from 'Theme/types/breakpoints.type';
import type CSS from 'csstype';

interface WrapperProps {
    $spans: {
        key: string;
        value: Span;
        breakpoint?: Breakpoint;
    }[];
    $align?: CSS.Property.AlignSelf;
    $justify?: CSS.Property.JustifySelf;
    $overflow?: CSS.Property.Overflow;
    $position?: CSS.Property.Position;
    $textAlign?: CSS.Property.TextAlign;
    $order?: CSS.Property.Order;
}

export const Wrapper = styled.div<WrapperProps>`
    ${({ $align, $justify, $overflow, $position, $textAlign, $order }) => css`
        grid-column-start: span 12;
        max-width: 100%;

        ${$overflow &&
        css`
            overflow: ${$overflow};
        `}
        ${$justify &&
        css`
            justify-self: ${$justify};
        `}
        ${$align &&
        css`
            align-self: ${$align};
        `}
        ${$position &&
        css`
            position: ${$position};
        `}
        ${$textAlign &&
        css`
            text-align: ${$textAlign};
        `}
        ${$order &&
        css`
            order: ${$order};
        `}
    `}

    ${({ $spans }) =>
        $spans.map(
            ({ value, breakpoint }) =>
                breakpoint &&
                css`
                    @media ${breakpoint.media} {
                        ${typeof value === 'number'
                            ? css`
                                  grid-column-start: span ${value};
                                  grid-column-end: unset;
                              `
                            : css`
                                  grid-column-start: ${value[0]};
                                  grid-column-end: ${value[1]};
                              `}
                    }
                `
        )}
`;
