import React from 'react';

import { Wrapper } from './Main.styled';

const Main: React.FC = props => {
    const { children } = props;

    return <Wrapper id="main">{children}</Wrapper>;
};

export default Main;
