import React, { useState, useEffect } from 'react';

import type { Props as SearchProps } from '../Search';

import { Container, CloseButton, CloseIcon, Search, ExpandButton } from './Site.styled';
import { SearchIcon } from '../Search.styled';

interface Props extends SearchProps {
    defaultIsOpen?: boolean;
    isCollapsible?: boolean;
    handleSearchOpen?: (isOpen: boolean) => void;
}

const a: React.FC<Props> = props => {
    const { isCollapsible = false, defaultIsOpen = false, handleSearchOpen, ...rest } = props;
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    useEffect(() => {
        if (typeof handleSearchOpen === 'function') {
            handleSearchOpen(isOpen);
        }
    }, [isOpen]);

    return (
        <Container $isOpen={isOpen}>
            {isCollapsible && isOpen && (
                <CloseButton onClick={() => setIsOpen(false)}>
                    <CloseIcon />
                </CloseButton>
            )}
            {isCollapsible && !isOpen && (
                <ExpandButton
                    as="button"
                    type="button"
                    aria-label="Open the search bar"
                    onClick={() => setIsOpen(true)}
                >
                    <SearchIcon />
                </ExpandButton>
            )}
            {(!isCollapsible || isOpen) && (
                <div>
                    <Search {...rest} />
                </div>
            )}
        </Container>
    );
};

export default a;
