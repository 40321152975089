import React, { useState, useEffect, useRef } from 'react';
import usePrevious from 'Common/hooks/usePrevious';
import { useSprings } from '@react-spring/web';
import { containsChild } from 'Common/util/node';

import type { Props as SubnavigationProps } from './subcomponents/Subnavigation';
import Subnavigation from './subcomponents/Subnavigation';

import {
    Navigation,
    NavList,
    NavItem,
    Button,
    ArrowIcon,
    NavigationIndicator,
    SubnavigationContainer
} from './SiteNavigation.styled';

export interface Props {
    items: { id: string; name: string; subnavigation: SubnavigationProps }[];
    isSticky?: boolean;
}

const SiteNavigation: React.FC<Props> = props => {
    const { items, isSticky = false } = props;
    const [openNavigationID, setOpenNavigationID] = useState<string | null>(null);
    const [keepOpen, setKeepOpen] = useState(false);
    const previousOpenNavigationID = usePrevious(openNavigationID);
    const navListRef = useRef() as React.MutableRefObject<HTMLUListElement>;

    const getSlideDirection = (index: number): 'left' | 'right' => {
        const previousIndex = items.findIndex(item => item.id === previousOpenNavigationID);

        return previousIndex < index ? 'left' : 'right';
    };

    const subnavigationOpenSprings = useSprings(
        items.length,
        items.map(item =>
            item.id === openNavigationID
                ? {
                      opacity: 1,
                      transform: 'perspective(125rem) translateX(-50%) rotateX(0deg)'
                  }
                : {
                      opacity: 0.5,
                      transform: 'perspective(125rem) translateX(-50%) rotateX(-5deg)'
                  }
        )
    );

    const subnavigationSlideLeftSprings = useSprings(
        items.length,
        items.map(item =>
            item.id === openNavigationID
                ? {
                      opacity: 1,
                      transform: 'translateX(-50%) rotateX(0deg)'
                  }
                : {
                      opacity: 1,
                      transform: `translateX(-52.5%) rotateX(0deg)`
                  }
        )
    );

    const subnavigationSlideRightSprings = useSprings(
        items.length,
        items.map(item =>
            item.id === openNavigationID
                ? {
                      opacity: 1,
                      transform: 'translateX(-50%) rotateX(0deg)'
                  }
                : {
                      opacity: 1,
                      transform: `translateX(-47.5%) rotateX(0deg)`
                  }
        )
    );

    useEffect(() => {
        const handleWindowClick = (event: MouseEvent) => {
            if (event.target && !navListRef.current.contains(event.target as Node)) {
                setKeepOpen(false);
                setOpenNavigationID(null);
            }
        };

        window.addEventListener('click', handleWindowClick);

        return () => window.removeEventListener('click', handleWindowClick);
    }, []);

    const handleNavigationClick: React.MouseEventHandler<HTMLElement> = event => {
        const target = event.target as HTMLElement;

        if (target.nodeName === 'A') {
            setKeepOpen(false);
            setOpenNavigationID(null);
        }
    };

    const handleNavItemClick = (id: string) => {
        if (openNavigationID === id && keepOpen) {
            setKeepOpen(false);
            setOpenNavigationID(null);
        } else {
            setKeepOpen(true);
            setOpenNavigationID(id);
        }
    };

    return (
        <Navigation onClick={handleNavigationClick} aria-label="Main site">
            <NavList ref={navListRef}>
                {items.map((item, index) => {
                    const isOpen = item.id === openNavigationID;
                    const direction = getSlideDirection(index);

                    return (
                        <NavItem
                            key={item.id}
                            $isOpen={isOpen}
                            onMouseOver={() => !keepOpen && setOpenNavigationID(item.id)}
                            onMouseOut={event =>
                                !keepOpen &&
                                !containsChild(
                                    navListRef.current,
                                    event.relatedTarget as HTMLElement
                                ) &&
                                setOpenNavigationID(null)
                            }
                        >
                            <Button onClick={() => handleNavItemClick(item.id)} type="button">
                                {item.name}
                                <ArrowIcon />
                                <NavigationIndicator $isOpen={isOpen} $index={index} />
                            </Button>
                            <SubnavigationContainer
                                $isOpen={isOpen}
                                $isSticky={isSticky}
                                style={
                                    previousOpenNavigationID === null
                                        ? subnavigationOpenSprings[index]
                                        : direction === 'left'
                                        ? subnavigationSlideLeftSprings[index]
                                        : subnavigationSlideRightSprings[index]
                                }
                            >
                                <Subnavigation {...item.subnavigation} />
                            </SubnavigationContainer>
                        </NavItem>
                    );
                })}
            </NavList>
        </Navigation>
    );
};

export default SiteNavigation;
