import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import Icon from 'Elements/Icon';

export const StyledIcon = styled(Icon)`
    ${({ theme }) => css`
        color: ${theme.colors.primaryContrast.hex};
        transform: translateY(-0.25rem) rotate(-90deg);
        height: 1.75rem;
        width: 1.75rem;
    `}
`;

export const Wrapper = styled(animated.button)`
    ${({ theme }) => css`
        position: fixed;
        bottom: 5.5rem;
        right: 2rem;
        z-index: 999;
        background: ${theme.colors.primary.hex};
        border-radius: 50%;
        display: flex;
        justify-content: flex-end;
        padding: 0.5rem;
        height: 2.25rem;
        max-width: 2.25rem;
        transition: 0.25s ease-in-out;
        cursor: pointer;
        border: none;

        @media ${theme.breakpoints.medium.media} {
            bottom: 6rem;
            right: 2.45rem;
        }

        &:hover,
        &:focus {
            background: ${theme.colors.primaryContrast.hex};
            border-radius: 1.5rem;
            box-shadow: 0 0 0 0.6rem rgba(0, 0, 0, 0.04);
            max-width: 10rem;

            ${StyledIcon} {
                color: ${theme.colors.primary.hex};
            }
        }
    `}
`;

export const Text = styled(animated.strong)`
    padding: 0.2rem 0.4rem 0;
    font-size: 1rem;
    white-space: nowrap;
    transform: translateY(-0.2rem);
`;
