import React from 'react';

import type { Props } from '../Button';

import { Container, IconContainer, IconBackground, ArrowIcon } from './Arrow.styled';

const Arrow: React.FC<Props> = props => {
    const { label, children, variant = 'default', ...rest } = props;

    return (
        <Container variant={variant} $variant={variant} {...rest}>
            {label ? label : children}
            <IconContainer>
                <IconBackground $variant={variant}>
                    <ArrowIcon $variant={variant} />
                </IconBackground>
            </IconContainer>
        </Container>
    );
};

export type { Props };
export default Arrow;
